import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`This is the Vessel Performance Monitoring documentation.`}</p>
    <h2 {...{
      "id": "what-is-vpm"
    }}>{`What is VPM?`}</h2>
    <p><strong parentName="p">{`Vessel Performance Management`}</strong>{` (or short `}<strong parentName="p">{`VPM`}</strong>{`) is a vessel performance data gathering, aggregation and analysis platform that enables you to input and monitor SOV and construction vessel activities on offshore wind farms.`}</p>
    <p>{`You can find the `}</p>
    <h2 {...{
      "id": "where-can-i-get-a-trial-account"
    }}>{`Where can I get a trial account?`}</h2>
    <p>{`You can contact support to request a trial account.`}</p>
    <h2 {...{
      "id": "how-do-i-reach-support"
    }}>{`How do I reach support?`}</h2>
    <p>{`You can contact support by writing to `}<a parentName="p" {...{
        "href": "mailto:vpm-support@ohb-ds.de"
      }}>{`vpm-support@ohb-ds.de`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      